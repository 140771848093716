// APP

export const SET_OS = 'APP/SET_OS';
export const SET_LANGUAGE = 'APP/SET_LANGUAGE';
export const FETCH_APP_DESCRIPTION = 'APP/FETCH_APP_DESCRIPTION';

export const HIDE_LOADER = 'APP/HIDE_LOADER';
export const SHOW_LOADER = 'APP/SHOW_LOADER';

export const SET_HEIGHT = 'APP/SET_HEIGHT';
export const IS_CART = 'APP/IS_CART';


// CATALOG
export const SCROLL_CATEGORY = 'CATALOG/SCROLL_CATEGORY';
export const FETCH_CATALOG = 'CATALOG/FETCH_CATALOG';
export const FETCH_CATEGORY = 'CATALOG/FETCH_CATEGORY';
export const FETCH_PRODUCT = 'CATALOG/FETCH_PRODUCT';
export const FETCH_CART_PRODUCTS = 'CATALOG/FETCH_CART_PRODUCTS';
export const PRODUCT_LOADING = 'CATALOG/PRODUCT_LOADING';


// WEBXR
export const SET_MATRIX = 'WEBXR/SET_MATRIX';
export const PLANE_DETECTED = 'WEBXR/PLANE_DETECTED';
export const RETICLE_HIT = 'WEBXR/RETICLE_HIT';
